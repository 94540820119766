import { Modal } from 'antd';
import {
    WifiOutlined,
  } from "@ant-design/icons";

const NetworkStatusModal = ({ isOpen, onClose }) => {
  return (
    <Modal
        open={isOpen}
        onCancel={onClose}
        footer={null}
      >
      <div>
        <div className="flex justify-center">
          <div className="flex justify-center rounded-full p-5 " style={{display: 'flex', justifyContent: 'center'}}>
            <WifiOutlined style={{color: 'red', fontSize: '80px'}} />
          </div>
        </div>
        <p className="text-center mt-10 mb-3" style={{color: '#000', fontWeight: '700', fontSize: '24px'}}>
          No Internet Connection
        </p>
        <p className="text-center pb-10" style={{ fontWeight: '500', fontSize: '16px'}}>
          Make sure WiFi or cellular data is turned on and then try again.
        </p>
      </div>
    </Modal>
  );
};

export default NetworkStatusModal;
