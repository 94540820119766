import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import store from 'store';
import { setLogout } from 'store/auth';
import useAuth from 'store/auth/useAuth';

const AutoLogout = () => {
  const { timeout } = useAuth();

  const [isActive, setIsActive] = useState(true);
  const navigate = useNavigate()

  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        store.dispatch(setLogout());
        localStorage.clear();
        // Redirect the user to the login page or any other desired page
        navigate('/login')
      }, timeout);

      setIsActive(true);
    };

    const handleResetTimeout = () => {
      if (isActive) {
        resetTimeout();
      }
    };

    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    events.forEach(event => {
      window.addEventListener(event, handleResetTimeout);
    });

    resetTimeout();

    return () => {
      clearTimeout(timeoutId);
      events.forEach(event => {
        window.removeEventListener(event, handleResetTimeout);
      });
    };
  }, [isActive, navigate, timeout]);

  return null;
};

export default AutoLogout;
