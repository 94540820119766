import React from 'react'
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: '/login',
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard',
        path: '/dashboard',
        component: React.lazy(() => import('views/dashboard'))
    },
    {
        key: 'students',
        path: '/students',
        component: React.lazy(() => import('views/students')),
    },
    {
        key: 'school-vendors',
        path: '/school-vendors',
        component: React.lazy(() => import('views/vendors')),
    },
    {
        key: 'school-merchants',
        path: '/school-merchants',
        component: React.lazy(() => import('views/merchants')),
    },
    {
        key: 'virtual-accounts',
        path: '/virtual-accounts',
        component: React.lazy(() => import('views/virtual-accounts')),
    },
]