import rootReducer from './rootReducer';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
	FLUSH,
	PAUSE,
	PERSIST,
	persistReducer,
	persistStore,
	REHYDRATE,
	PURGE,
	REGISTER,
  } from 'redux-persist';
  import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
  import storage from 'redux-persist/lib/storage';
  import auth from './slices/authSlice'
  import authSlice from './auth'
import themeSlice from './slices/themeSlice';

const middlewares = [];

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
	stateReconciler: autoMergeLevel2, // optional: specify the state reconciler strategy
	whitelist: ['theme', auth, authSlice.name],
  };

  const persistedReducer = persistReducer(
	persistConfig,
	combineReducers({
		theme: themeSlice,
        auth,
        [authSlice.name]: authSlice.reducer,
	})
  );

const store = configureStore({
	// reducer: rootReducer(),
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			 serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
		}).concat(middlewares),
	devTools: process.env.NODE_ENV === 'development',
})

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
	if (store.asyncReducers[key]) {
		return false;
	}
	store.asyncReducers[key] = reducer;
	store.replaceReducer(rootReducer(store.asyncReducers));
	return store
}

export const persistor = persistStore(store);
export default store

