import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import store from "./store";
import history from "./history";
import Layouts from "./layouts";
import { THEME_CONFIG } from "./configs/AppConfig";
import mockServer from "./mock";
import "./lang";
import { QueryClient, QueryClientProvider } from "react-query";
import ErrorBoundary from "components/shared-components/ErrorBoundary";
import AutoLogout from "components/shared-components/AutoLogout";
import NetworkStatusModal from "components/shared-components/NetworkStatusModal";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const environment = process.env.NODE_ENV;

// if (environment !== 'production') {
// 	mockServer({ environment })
// }

function App() {
  const queryClient = new QueryClient();
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const handleNetworkChange = () => {
      setIsOnline(navigator.onLine);
    };
    window.addEventListener('offline', handleNetworkChange);
    window.addEventListener('online', handleNetworkChange);
    return () => {
      window.removeEventListener('offline', handleNetworkChange);
      window.removeEventListener('online', handleNetworkChange);
    };
  }, [])

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ErrorBoundary>
            <BrowserRouter history={history}>
              <ThemeSwitcherProvider
                themeMap={themes}
                defaultTheme={THEME_CONFIG.currentTheme}
                insertionPoint="styles-insertion-point"
              >
                <Layouts />
              </ThemeSwitcherProvider>
              <AutoLogout />
              <NetworkStatusModal isOpen={!isOnline} />
            </BrowserRouter>
          </ErrorBoundary>
        </Provider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
