/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  token: null,
  user: {},
  timeout: 300000, // Set inactivity timeout (5 minutes = 300000 milliseconds)
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.token = action.payload.token;
      state.user = { ...state.user, ...action.payload.user };
    },
    setLogout: state => {
      // Reset the state of authSlice
      Object.assign(state, initialState);
    },
  },
});

export const { setCurrentUser, setLogout } = authSlice.actions;

export default authSlice;
